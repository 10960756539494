<template>
  <div id="home">
    <!--  背景渐变色  -->
    <div class="background" :style="{background:carouselList[carouselIndex].back_color}">
      <!--  头部导航  -->
      <div class="header">
        <div class="header_first">
          <div class="header_first_left">
            <div class="header_first_left_top">
              <div class="hot_service_img">
                HOT
              </div>
              <div class="hot_service_text">热门服务</div>
            </div>
            <div class="header_first_left_bot">
              <div class="game_type_list">
                <div class="type_name">
                  <img src="@/assets/image/Home/gameType1.png" alt="">
                  <span>游戏账号</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="type_game">
                  天涯明月刀&emsp;剑灵
                </div>
                <!--  热门服务 遮罩层  -->
                <div class="game_type_bullet game">
                  <!--  热门服务 顶部  -->
                  <div class="game_type_bullet_title">
                    <div class="game_type_bullet_title_top">
                      <div class="bullet_title">
                        <div :class="game_click == ''?'game_click':''" @click="changeGame_click('')">
                          全部游戏
                        </div>
                        <div v-for="(item,index) in gameClass" :key="index"
                             :class="game_click == item.id?'game_click':''" @click="changeGame_click(item.id)">
                          {{ item.class_name }}
                        </div>

                        <!--                        <div id="peGame" :class="game_click == 2?'game_click':''" @click="changeGame_click(2)">-->
                        <!--                          手机游戏-->
                        <!--                        </div>-->
                      </div>
                      <router-link :to="{name:'AllGame'}" class="game_type_bullet_area">全部<i
                        class="el-icon-arrow-right"></i></router-link>
                    </div>
                    <div class="game_type_bullet_title_bot">
                      <div class="list_item opiton">
                        <div>
                          <span :class="game_letter===item?'active_A-Z':''" v-for="item in alphabet" :key="item"
                                v-text="item" @click="getgame_list(item)"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  热门服务 内容  -->
                  <div class="game_type_bullet_list">
                    <a class="game_type_bullet_list_item" v-for="(item,index) in game_list" :key="index"
                         :id="index % 6 !== 0 ?'line_not_first_item':''" @click="pc_game_item(item,1)">
                      <div class="game_type_bullet_list_item_img">
                        <img :src="item.game_image" alt="">
                      </div>
                      <div class="game_type_bullet_list_item_text">{{ item.game_name }}</div>
                    </a>
                  </div>
                </div>
              </div>
              <router-link :to="{name:'AccountValuation'}" class="game_type_list accountValuation">
                <!--             <router-link :to="{name:''}" class="game_type_list accountValuation">-->
                <div class="type_name">
                  <img src="@/assets/image/Home/gameType2.png" alt="">
                  <span>账号估价</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="type_game">
                  天涯明月刀&emsp;英雄联盟
                </div>
                <!--               <div class="inDev">-->
                <!--                  <div>该功能正在开发中，敬请期待！</div>-->
                <!--                </div>-->
              </router-link>
              <div class="game_type_list first_recharge">
                <div class="type_name">
                  <img src="@/assets/image/Home/gameType3.png" alt="">
                  <span>装备道具</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="type_game">
                  QQ三国
                </div>
                <!--  热门服务 遮罩层  -->
                <div class="game_type_bullet first_recharge">
                  <!--  热门服务 顶部  -->
                  <div class="game_type_bullet_title">
                    <div class="game_type_bullet_title_top">
                      <div class="bullet_title">
                        <div :class="game_click == ''?'game_click':''" @click="changeGame_click('')">
                          全部游戏
                        </div>
                        <div v-for="(item,index) in gameClass" :key="index"
                             :class="game_click == item.id?'game_click':''" @click="changeGame_click(item.id)">
                          {{ item.class_name }}
                        </div>
                      </div>
                      <router-link :to="{name:'AllGame'}" class="game_type_bullet_area">全部<i
                        class="el-icon-arrow-right"></i></router-link>
                    </div>
                    <div class="game_type_bullet_title_bot">
                      <div class="list_item opiton">
                        <div>
                          <span :class="game_letter===item?'active_A-Z':''" v-for="item in alphabet" :key="item"
                                v-text="item" @click="getgame_list(item)"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  热门服务 内容  -->
                  <div class="game_type_bullet_list">
                    <a class="game_type_bullet_list_item" v-for="(item,index) in game_listEquip" :key="index"
                         :id="index % 6 !== 0 ?'line_not_first_item':''" @click="pc_game_item(item,2)">
                      <div class="game_type_bullet_list_item_img">
                        <img :src="item.game_image" alt="">
                      </div>
                      <div class="game_type_bullet_list_item_text">{{ item.game_name }}</div>
                    </a>
                  </div>
                </div>
              </div>
<!--              <div class="game_type_list gold">
                <div class="type_name">
                  <img src="@/assets/image/Home/gameType2.png" alt="">
                  <span>游戏金币</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="type_game">
                  QQ三国
                </div>
                <div class="inDev">
                  <div>该功能正在开发中，敬请期待！</div>
                </div>
                &lt;!&ndash;  热门服务 遮罩层  &ndash;&gt;
                &lt;!&ndash;                <div class="game_type_bullet gold">&ndash;&gt;
                &lt;!&ndash;                  &lt;!&ndash;  热门服务 顶部  &ndash;&gt;&ndash;&gt;
                &lt;!&ndash;                  <div class="game_type_bullet_title">&ndash;&gt;
                &lt;!&ndash;                    <div class="game_type_bullet_title_top">&ndash;&gt;
                &lt;!&ndash;                      <div class="bullet_title">&ndash;&gt;
                &lt;!&ndash;                        <div :class="game_click == ''?'game_click':''" @click="changeGame_click('')">&ndash;&gt;
                &lt;!&ndash;                          全部游戏&ndash;&gt;
                &lt;!&ndash;                        </div>&ndash;&gt;
                &lt;!&ndash;                        <div v-for="(item,index) in gameClass" :key="index"&ndash;&gt;
                &lt;!&ndash;                             :class="game_click == item.id?'game_click':''" @click="changeGame_click(item.id)">&ndash;&gt;
                &lt;!&ndash;                          {{ item.class_name }}&ndash;&gt;
                &lt;!&ndash;                        </div>&ndash;&gt;
                &lt;!&ndash;                      </div>&ndash;&gt;
                &lt;!&ndash;                      <router-link :to="{name:'AllGame'}" class="game_type_bullet_area">全部<i&ndash;&gt;
                &lt;!&ndash;                        class="el-icon-arrow-right"></i></router-link>&ndash;&gt;
                &lt;!&ndash;                    </div>&ndash;&gt;
                &lt;!&ndash;                    <div class="game_type_bullet_title_bot">&ndash;&gt;
                &lt;!&ndash;                      <div class="list_item opiton">&ndash;&gt;
                &lt;!&ndash;                        <div>&ndash;&gt;
                &lt;!&ndash;                          <span :class="game_letter===item?'active_A-Z':''" v-for="item in alphabet" :key="item"&ndash;&gt;
                &lt;!&ndash;                                v-text="item" @click="getgame_list(item)"></span>&ndash;&gt;
                &lt;!&ndash;                        </div>&ndash;&gt;
                &lt;!&ndash;                      </div>&ndash;&gt;
                &lt;!&ndash;                    </div>&ndash;&gt;
                &lt;!&ndash;                  </div>&ndash;&gt;
                &lt;!&ndash;                  &lt;!&ndash;  热门服务 内容  &ndash;&gt;&ndash;&gt;
                &lt;!&ndash;                  <div class="game_type_bullet_list">&ndash;&gt;
                &lt;!&ndash;                    <div class="game_type_bullet_list_item" v-for="(item,index) in game_listGold" :key="index"&ndash;&gt;
                &lt;!&ndash;                         :id="index % 6 !== 0 ?'line_not_first_item':''" @click="pc_game_item(item,3)">&ndash;&gt;
                &lt;!&ndash;                      <div class="game_type_bullet_list_item_img">&ndash;&gt;
                &lt;!&ndash;                        <img :src="item.game_image" alt="">&ndash;&gt;
                &lt;!&ndash;                      </div>&ndash;&gt;
                &lt;!&ndash;                      <div class="game_type_bullet_list_item_text">{{ item.game_name }}</div>&ndash;&gt;
                &lt;!&ndash;                    </div>&ndash;&gt;
                &lt;!&ndash;                  </div>&ndash;&gt;
                &lt;!&ndash;                </div>&ndash;&gt;
              </div>-->
              <router-link :to="{name:'AccountRecycle'}" class="game_type_list">
                <div class="type_name">
                  <img src="@/assets/image/Home/gameType4.png" alt="">
                  <span>账号回收</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="type_game">
                  天涯明月刀&emsp;剑灵
                </div>
                <!--                <div class="game_type_bullet account_recycle">-->
                <!--                  &lt;!&ndash;  热门服务 顶部  &ndash;&gt;-->
                <!--                  <div class="game_type_bullet_title">-->
                <!--                    <div class="game_type_bullet_title_top">-->
                <!--                      <div class="bullet_title">账号回收</div>-->
                <!--                      <router-link tag="div" class="game_type_bullet_area" :to="{name:'accountRecycle'}">进入账号回收专区 <i class="el-icon-arrow-right"></i></router-link>-->
                <!--                    </div>-->
                <!--                    <div class="game_type_bullet_title_bot">-->
                <!--                      <div class="list_item opiton">-->
                <!--                        <div>-->
                <!--                          <span :class="account_recycle_letter===item?'active_A-Z':''" v-for="item in alphabet" :key="item" v-text="item" @click="getaccount_recycle_list(item)"></span>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </div>-->

                <!--                  </div>-->
                <!--                  &lt;!&ndash;  热门服务 内容  &ndash;&gt;-->
                <!--                  <div class="game_type_bullet_list">-->
                <!--                    <div class="game_type_bullet_list_item" v-for="(item,index) in account_recycle_list" :key="index" :id="index % 6 !== 0 ?'line_not_first_item':''">-->
                <!--                      <div class="game_type_bullet_list_item_img">-->
                <!--                        <img :src="item.game_image" alt="">-->
                <!--                      </div>-->
                <!--                      <div class="game_type_bullet_list_item_text">{{item.game_name}}</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </router-link>
            </div>
          </div>
          <div class="header_first_mid">
            <el-carousel @change="getCarouselIndex">
              <el-carousel-item v-for="(item,index) in carouselList" :key="index">
                <h3 class="small">
                  <!--                  <img src="../../assets/image/Home/dailyTransactionItem.jpeg" alt="">-->
                  <img :src="item.show_img" :alt="item.show_text" @click="clickCarousel(item)">
                </h3>
              </el-carousel-item>
            </el-carousel>
            <div class="trade">
              <div class="trade_top">
                <div class="trade_top_img">
                  <img src="@/assets/image/Home/tradeLogo.png" alt="/">
                </div>
                <div class="trade_top_text">
                  安全完成<span>{{ tradeCount }}</span>笔交易
                </div>
              </div>
              <div class="trade_bot">
                <div>当前在售号 <span>{{ inStock }}</span></div>
                <img src="@/assets/image/Home/gameTypeBullet2.png" alt="">
                <div>昨日访问量 <span>{{ views }}</span></div>
                <img src="@/assets/image/Home/gameTypeBullet2.png" alt="">
                <div>昨日成交量 <span>{{ toTrade }}</span></div>
              </div>
            </div>
          </div>
          <div class="header_first_right">
            <div class="header_first_right_top">
              <div class="userInfo">
                <div class="userInfo_img">
                  <!--                  <img src="../../assets/image/Home/userAvatar.jpeg" alt="">-->
                  <img v-if="this.$store.state.userData.touxiang && this.$store.state.userData.touxiang !== ''"
                       :src="this.$store.state.userData.touxiang" alt="">
                  <img v-else src="@/assets/image/PersonalCenter/MyAccount/userAvatar.png" alt="">
                </div>
                <div class="userInfo_account">
                  <span class="userInfo_account_top">{{ this.$store.state.userData.telphone }}</span><br>
                  <span class="userInfo_account_bot" v-if="this.$store.state.loginStatus">
                    <router-link tag="span" :to="{name:'PersonalCenter'}">个人中心</router-link>
                    丨
                    <span @click="logout">退出</span>
                  </span>
                  <span class="userInfo_account_bot" v-if="!this.$store.state.loginStatus">
                    <router-link tag="span" :to="{name:'Login'}">登录</router-link>
                    丨
                    <router-link tag="span" :to="{name:'Register'}">注册</router-link>
                  </span>
                </div>
              </div>
              <div class="btn_ground">
                <router-link :to="{name:'AllGame'}" class="buyNumber">
                  <div>
                    <img src="@/assets/image/Home/buyNumberIco.png" alt="">
                  </div>
                  <div>我要买</div>
                  <div class="help_wrapper">
                    <el-button type="text" @click.prevent="goHelp(278,9)"><i class="el-icon-question"></i>如何买号</el-button>
                  </div>
                </router-link>
<!--                                <router-link :to="{name:'AccountSell'}" class="sellNumber">-->
                <a class="sellNumber" @click="sellClick">
                  <div>
                    <img src="@/assets/image/Home/sellNumberIco.png" alt="">
                  </div>
                  <div>我要卖</div>
<!--                                  </router-link>-->
                </a>
                <router-link type="button" class="Recycle" :to="{name:'CusSerCenter'}">
                  <div>找客服</div>
                  <div class="help_wrapper">
                    <el-button type="text" @click.prevent="goHelp(261,10)"><i class="el-icon-question"></i>如何联系客服</el-button>
                    <el-button type="text" @click.prevent="goHelp(260,10)">
                      <i class="el-icon-question"></i>
                      如何验证客服和收款户真伪</el-button>
                  </div>
                </router-link>
              </div>
              <div class="verify">
<!--                <div class="header_second_right_item">
                  <div class="header_second_right_text">客服验证</div>
                  <div class="header_second_input_box" >
                    <input type="text" v-model.trim="cusSerVerify" class="header_second_right_inptut">
                    <router-link tag="span" :to="{name:'CusSerVerify',params:{num:cusSerVerify}}">查询</router-link>
                  </div>
                </div>-->
                <div class="header_second_right_item">
                  <div class="header_second_right_text">黑号验证</div>
                  <div class="header_second_input_box" >
                    <input type="text" v-model.trim="blackNumVerify" class="header_second_right_inptut">
                    <router-link tag="span" :to="{name:'BlackNumVerify',params:{num:blackNumVerify}}">查询</router-link>
                  </div>

                </div>
              </div>

            </div>
            <div class="header_first_right_bottom">
              <div class="announcement_title">
                <div class="a_title_img">
                  <img src="@/assets/image/Home/announcement.png" alt="">
                </div>
                <div class="a_title_text">公告</div>
                <router-link tag="a" class="a_title_more" :to="{name:'Article',query:{cat_id:'1'}}">
                  更多
                  <i class="el-icon-arrow-right"></i>
                </router-link>
              </div>
              <div class="announcement_content">
                <router-link v-for="(item,index) in article_list.slice(0,3)" :key="index"
                             :to="{name:'Article',query:{cat_id:'1',id:item.id}}">
                  {{ item.title }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="header_third">
          <div class="header_third_img">
            <img src="@/assets/image/Home/trumpet.png" alt="">
            <div>温馨提示：</div>
          </div>
          <div class="header_third_text">
            <div class="notice-card-wrapper">
              <span v-html="notice"></span>
            </div>
          </div>
        </div>
        <div class="header_four">
          <router-link @mouseenter.native="bitmap1 = true" @mouseleave.native="bitmap1=false"
             :to="{name:'TradeTip',query:{id:'15',parent_id:'13'}}">
            <img src="../../assets/image/Home/bitmap1.png" alt="">
          </router-link>
          <router-link @mouseenter.native="bitmap2 = true" @mouseleave.native="bitmap2=false"
                       :to="{name:'Legal',query:{id:'113',parent_id:'13'}}">
            <img src="../../assets/image/Home/bitmap2.png" alt="">
          </router-link>
          <router-link @mouseenter.native="bitmap3 = true" @mouseleave.native="bitmap3=false"
                       :to="{name:'TradeTip',query:{id:'109',parent_id:'83'}}">
            <img src="../../assets/image/Home/bitmap3.png" alt="">
          </router-link>
        </div>
      </div>
    </div>
    <!--  首页内容  -->
    <div class="main_body">
      <div class="account_ground">
        <!--  顶级账号  -->
        <div class="top_account">
          <div class="top_account_title">
            <div class="top_account_title_logo">
              顶级账号
            </div>
            <div class="top_account_title_list">
              <span class="top_account_title_list_item" :id="topAccount_games_select===item.game_name?'active':''"
                    @click="clickTopAccountGames(item)"

                    v-for="(item,index) in topAccount_games" :key="index">
                {{ item.game_name }}
              </span>
            </div>
            <router-link class="top_account_title_list_more"
                         :to="topAccount_games_select_id=='26'?{name:'SelectGame',query:{game_id:this.topAccount_games_select_id,game_alias:'yuanshen5'},
                         params:{game_name:this.topAccount_games_select,game_id:this.topAccount_games_select_id,game_class:this.topAccount_games_select_class,rec:'dingji',breadCrumbs:'顶级账号'}}
                         :{name:'SelectGame',query:{game_id:this.topAccount_games_select_id,game_alias:'games'},
                         params:{game_name:this.topAccount_games_select,game_id:this.topAccount_games_select_id,game_class:this.topAccount_games_select_class,rec:'dingji',breadCrumbs:'顶级账号'}}
">
              更多 <i class="el-icon-arrow-right"></i>
            </router-link>
          </div>
          <div class="top_account_content">
            <a class="top_account_content_item" v-for="(item,index) in top_account_info" :key="index"
                 @click="topItemClick(item)">
              <div class="top_account_content_item_img">
                <!--                <img src="../../assets/image/Home/dailyTransactionItem.jpeg" alt="">-->
                <img :src="item.image" alt="" >
              </div>
              <div class="top_account_content_item_info">
                <div class="top_account_content_item_info_top">
                  <span>{{ topAccount_games_select }}</span>
                  {{ item.name }}
                </div>
                <div class="top_account_content_item_info_bot">
                  <div id="top_account_info_left">
                    <div id="top_account_info_hot">
                      <img src="@/assets/image/Home/topGamesIco.png" alt="">
                      {{ item.click }}
                    </div>
                    <!-- <div id="top_account_info_date">
                      {{ item.up_time|moment }}
                    </div> -->
                  </div>
                  <div id="top_account_info_right" v-if="item.price > 0">
                    ¥<span>{{ item.price }}</span>
                  </div>

                </div>
              </div>
              <!-- 商品详情 -->
              <div class="top_account_content_item_detail">
                <!-- 商品详情标题 -->
                <div id="top_account_content_item_detail_title">商品详情</div>
                <!-- 商品详情内容 -->
                <div id="top_account_content_item_detail_content">
                  {{ item.name }}
                </div>
              </div>
            </a>
          </div>
        </div>
        <!--  最新账号  -->
        <div class="latest_account">
          <div class="latest_account_title">
            <div class="latest_account_title_logo">
              最新账号
            </div>
            <div class="latest_account_title_list">
              <div class="latest_account_title_list_item" :id="newAccount_games_select===item.game_name?'active':''"
                   @click="clickNewAccountGames(item)"
                   v-for="(item,index) in newAccount_games" :key="index">
                {{ item.game_name }}
              </div>
            </div>
            <router-link  class="latest_account_title_list_more"
                         :to="newAccount_games_select_id=='26'?{name:'SelectGame',query:{game_id:this.newAccount_games_select_id,game_alias:'yuanshen5'},params:{game_name:this.newAccount_games_select,game_id:this.newAccount_games_select_id,game_class:this.newAccount_games_select_class,type:'new',breadCrumbs:'最新账号'}}
                         :{name:'SelectGame',query:{game_id:this.newAccount_games_select_id,game_alias:'games'},params:{game_name:this.newAccount_games_select,game_id:this.newAccount_games_select_id,game_class:this.newAccount_games_select_class,type:'new',breadCrumbs:'最新账号'}}">
              更多 <i class="el-icon-arrow-right"></i>
            </router-link>
          </div>
          <div class="latest_account_content">
            <div class="latest_account_content_item" v-for="(item,index) in new_account_info" :key="index"
                 @click="latestItemClick(item)">
              <div class="latest_account_content_item_img">
                <!--                <img src="../../assets/image/Home/dailyTransactionItem.jpeg" alt="">-->
                <img :src="item.image" alt="" >
              </div>
              <div class="latest_account_content_item_info">
                <div class="latest_account_content_item_info_top">
                  <span>{{ newAccount_games_select }}</span>
                  {{ item.name }}
                </div>
                <div class="latest_account_content_item_info_bot">
                  <div id="latest_account_info_left">
                    <div id="latest_account_info_hot">
                      <img src="@/assets/image/Home/topGamesIco.png" alt="">
                      {{ item.click }}
                    </div>
                    <!-- <div id="latest_account_info_date">
                      {{ item.up_time|moment }}
                    </div> -->
                  </div>
                  <div id="latest_account_info_right" v-if="item.price> 0">
                    ¥<span>{{ item.price }}</span>
                  </div>
                </div>
              </div>
              <!-- 商品详情 -->
              <div class="latest_account_content_item_detail">
                <!-- 商品详情标题 -->
                <div id="latest_account_content_item_detail_title">商品详情</div>
                <!-- 商品详情内容 -->
                <div id="latest_account_content_item_detail_content">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  每日成交  -->
      <div class="daily_transaction">
        <div class="daily_transaction_title">
          <div class="daily_transaction_title_logo">
            <img src="@/assets/image/Home/dailyTransaction.png" alt="">
          </div>
          <router-link  class="daily_transaction_title_more"
                       :to="{path:'home/dailyTxDetails',query:{game_id:dailyTransaction[0].game_id,time:dailyTransaction[0].pay_time}}">
            更多
            <i class="el-icon-arrow-right"></i>
          </router-link>
          <!--          <div class="daily_transaction_title_more">-->
          <!--            更多-->
          <!--            <i class="el-icon-arrow-right"></i>-->
          <!--          </div>-->
        </div>
        <!--        <div class="daily_transaction_filter">-->
        <!--          <div class="daily_transaction_filter_title">-->
        <!--            <span></span>-->
        <!--            <span>{{ value.label }}</span>-->
        <!--          </div>-->
        <!--          <div class="daily_transaction_filter_select">-->
        <!--            <el-select :popper-append-to-body="false" v-model="value" placeholder="请选择" @change="selectChange">-->
        <!--              <el-option v-for="item in options" :key="item.value" :label="item.label"-->
        <!--                         :value="{value:item.value,label:item.label}"></el-option>-->
        <!--            </el-select>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="daily_transaction_content">
          <a class="daily_transaction_content_item" v-for="(item,index) in dailyTransaction" :key="index"
               @click="daily_Tx_CLick(item)">
            <div class="DTCI_top">
              <img v-if="index === 0" src="../../assets/image/Home/dailyTransactionTop1.png" alt="">
              <img v-if="index === 1" src="../../assets/image/Home/dailyTransactionTop2.png" alt="">
              <img v-if="index === 2" src="../../assets/image/Home/dailyTransactionTop3.png" alt="">
            </div>
            <div class="DTCI_img">
              <img :src="item.img" alt="">
            </div>
            <div class="DTCI_content">
              <div class="DTCI_gameName">{{ item.game_name }}</div>
              <div class="DTCI_orderNum">昨日交易{{ item.count }}单</div>
              <div class="DTCI_date">
                <div id="DTCI_date">{{ item.pay_time|moment }}</div>
                <div id="DTCI_btn">查看详情</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <SellPop :sellPop="sellPop"></SellPop>
    <div class="appDownload" v-if="appDownload">
      <router-link class="ad-conetnt" :to="{name:'Download'}">
        <img class="conetnt" src="@/assets/image/Download/ad-content.png" alt="">
        <img class="qrcode" src="@/assets/image/Download/ad-qrcode.gif" alt="">
      </router-link>
      <img class="btn" src="@/assets/image/Download/appDownloadEsc.png" @click="appDownload = false" alt="">
    </div>
    <el-dialog
      :visible.sync="bannerWxDialog"
      custom-class="bannerWxDialog"
      :show-close="false"
      width="303px"
      top="20vh"
      center>
        <span>
          <div class="banner-dia-main">
            <div class="banner-dia-top">
              企业微信
            </div>
            <div class="banner-dia-wx">
              <img src="@/assets/image/Home/bannerWx.png">
              <div class="banner-dia-c">
                <img src="@/assets/image/Home/copyright.png">
                金华博淳网络科技
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="banner-dia-sys">
                <img src="@/assets/image/Home/bannerSs.png">
                商务合作请扫码加V
              </div>
            </div>
          </div>
          <img class="banner-dia-close" src="@/assets/image/AccountDeal/close.png" @click="bannerWxDialog = false">
        </span>
    </el-dialog>
  </div>
</template>

<script>
import SellPop from './SellPop/index'
import {
  apiPTotal,
  apiDArticleList,
  apiProductTop,
  apiProductNew,
  apiGameList,
  apiDesktopIndex,
  apiShowList,
  apiLogout,
  apiCustomerSys,
  apiGameClass,
  apiYesterdayDeal
} from '@/request/API';

export default {
  components: {
    SellPop
  },
  data() {
    return {
      // 招商轮播图对话框
      bannerWxDialog: false,
      appDownload:false,
      bitmap1: false,
      bitmap2: false,
      bitmap3: false,
      //客服服务中心
      cusSerCenter: false,
      //我要卖
      sellPop:false,
      //A-Z
      action: {
        gameTypeId: null,
        firstChar: "全部",
      },
      alphabet: [],
      //背景渐变色
      backgroundColor: [
        // 'linear-gradient(180deg, rgba(194, 190, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)',
        // 'linear-gradient(180deg, rgba(200, 186, 175, 0.5) 0%, rgba(255, 255, 255, 0) 100%)',
        // 'linear-gradient(180deg, rgba(153, 157, 173, 0.5) 0%, rgba(255, 255, 255, 0) 100%)',
      ],
      // 遮罩层 网络游戏 列表
      game_click: '',
      game_letter: '全部',
      gameClass: [],
      game_list: [],
      game_listGold: [],
      game_listEquip: [],
      // 已安全完成***笔交易
      tradeCount: 0,
      // 当前在售号
      inStock: 0,
      // 昨日访问量
      views: 0,
      // 昨日成交量
      toTrade: 0,
      // 1F 顶级账号 游戏tabbar列表
      topAccount_games: [],
      //1F 顶级账号 tabbar选中
      topAccount_games_select: "天涯明月刀",
      topAccount_games_select_id: '',
      topAccount_games_select_class: '',
      //1F item
      top_account_info: [],
      // 2F 最新账号 游戏tabbar列表
      newAccount_games: [],
      //2F 最新账号 tabbar选中
      newAccount_games_select: "天涯明月刀",
      newAccount_games_select_id: '',
      newAccount_games_select_class: '',
      //2F item
      new_account_info: [],

      //温馨提示
      notice: "uu手游为您提供最安全的游戏交易，切勿私下交易谨防诈骗，平台客服均为企业QQ。",
      //最新成交记录
      animate: true,
      //最新成交记录轮播列表
      marqueeList: [
        {id: '1', user_phone: '158*****7571', game_name: '天涯明月刀', deal_money: '123'},

      ],
      //客服验证
      cusSerVerify: '',
      //黑号验证
      blackNumVerify: '',
      //每日成交下拉框
      options: [],
      //每日成交下拉框选中
      value: '',
      //每日成交列表
      dailyTransaction: [{
        game_id: '',
        pay_time: ''
      }],
      //当前幻灯片列表
      carouselList: [
        {back_color: ''}
      ],
      //当前幻灯片索引
      carouselIndex: 0,
      //公告栏列表
      article_list: [],
    }
  },
  methods: {
    /**
     * @description: 我要买，找客服跳转帮助中心
     * @return {*}
     */
    goHelp(id,cat_id) {
      let newPage = this.$router.resolve({
        name: 'helpDetail',
        query: {
          id,
          cat_id
        }
      })
      window.open(newPage.href, '_blank')
    },
    //我要卖 点击事件
    escSellPop(bool) {
      this.sellPop = bool
    },
    // 遮罩层 网络游戏 列表
    getgame_list(item) {
      if (!item) {
        this.game_letter = '全部'
      } else {
        this.game_letter = item
      }
      if (item === '全部') {
        item = ''
      }
      this.game_list = []
      this.game_listGold = []
      this.game_listEquip = []
      apiGameList({
        class_id: this.game_click,
        game_letter: item,
      }).then(res => {
        res.data.forEach((item, index) => {
          item.game_service.forEach(gsItem => {
            switch (gsItem.id) {
              case 1:
                this.game_list.push(item)
                break;
              case 2:
                this.game_listEquip.push(item)
                break;
              case 3:
                this.game_listGold.push(item)
                break;
            }
          })
        })
      })
    },
    changeGame_click(data) {
      this.game_list = []
      this.game_listGold = []
      this.game_listEquip = []
      this.game_click = data
      this.game_letter = '全部'
      apiGameList({
        class_id: this.game_click,
      }).then(res => {
        res.data.forEach((item, index) => {
          item.game_service.forEach(gsItem => {
            switch (gsItem.id) {
              case 1:
                this.game_list.push(item)
                break;
              case 2:
                this.game_listEquip.push(item)
                break;
              case 3:
                this.game_listGold.push(item)
                break;
            }
          })
        })
      })
    },
    pc_game_item(item, index) {
      if (item.game_id == "26") {
        this.$router.push({
          path: 'selectGame',
          name: 'SelectGame',
          query: {
            game_id: item.game_id,
            game_alias: 'yuanshen5'
          },
          params: {
            game_name: item.game_name,
            game_class: item.game_class,
            game_id: item.game_id,
            productTypeSelect: index
          }
        })
      } else {
        this.$router.push({
          path: 'selectGame',
          name: 'SelectGame',
          query: {
            game_id: item.game_id
            , game_alias: 'games'
          },
          params: {
            game_name: item.game_name,
            game_class: item.game_class,
            game_id: item.game_id,
            productTypeSelect: index
          }
        })
      }

    },
    //热门服务item点击事件
    gameTypeListClick() {
      this.$message({
        center: true,
        type: "info",
        message: '开发中，敬请期待！'
      })
    },

    //我要卖 按钮 点击事件
    sellClick(){
      this.sellPop = true
    },
    //每日成交下拉框监听事件
    selectChange(event) {
      apiYesterdayDeal({}).then(res => {
        this.dailyTransaction = res.data
      })
    },
    //最新成交记录函数
    showMarquee: function () {
      this.animate = true;
      setTimeout(() => {
        this.marqueeList.push(this.marqueeList[0]);
        this.marqueeList.shift();
        this.animate = false;
      }, 500)
    },
    // 1F 顶级账号tabbar选中
    clickTopAccountGames(item) {
      this.topAccount_games_select = item.game_name
      this.topAccount_games_select_id = item.game_id
      this.topAccount_games_select_class = item.game_class
      apiProductTop({
        game_id: item.game_id,
        pageSize: 6
      }).then(res => {
        this.top_account_info = res.data
      })
    },
    // 1F 顶级账号 item 点击事件
    topItemClick(item) {
      let routeUrl = this.$router.resolve({
        name: 'GameDetails',
        query: {
          game_id: this.topAccount_games_select_id,
          id: item.id
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 2F 最新账号tabbar选中
    clickNewAccountGames(item) {
      this.newAccount_games_select = item.game_name
      this.newAccount_games_select_id = item.game_id
      this.newAccount_games_select_class = item.game_class
      apiProductNew({
        game_id: item.game_id,
        pageSize: 6
      }).then(res => {
        this.new_account_info = res.data
      })
    },
    // 2F 最新账号 item 点击事件
    latestItemClick(item) {
      let routeUrl = this.$router.resolve({
        name: 'GameDetails',
        query: {
          game_id: this.newAccount_games_select_id,
          id: item.id
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 每日成交 item 点击事件
    daily_Tx_CLick(item) {
      this.$router.push({
        path: '/home/dailyTxDetails',
        name: 'DailyTxDetails',
        query: {
          game_id: item.game_id,
          time: item.pay_time,
        },
      })
    },
    /**
     * 轮播图回调函数
     * @param key1 目前激活的幻灯片的索引
     * @param key2 原幻灯片的索引
     */
    getCarouselIndex: function (key1, key2) {
      this.carouselIndex = key1
    },
    //轮播图点击事件
    clickCarousel(item) {
      if(item.show_name == "招商入驻"){
        this.bannerWxDialog = true
      } else {
        window.open(item.show_link,'_black')
      }
    },
    //初始化首页数据
    onLoad() {
      //获取 当前在售号 && 今日访问量
      apiPTotal({}).then(res => {
        this.tradeCount = res.data.dealTotal
        this.inStock = res.data.saleTotal
        this.views = res.data.pv
        this.toTrade = res.data.yesterday
      })
      //获取 公告栏列表
      apiDArticleList({}).then(res => {
        this.article_list = res.data
      })
      //获取游戏列表
      apiGameList({}).then(res => {
        // this.game_list = res.data
        this.topAccount_games = res.data
        this.newAccount_games = res.data
        res.data.forEach((item, index) => {
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          if (index === 0) {
            this.value = options
            this.topAccount_games_select_id = item.game_id
            this.topAccount_games_select = item.game_name
            this.topAccount_games_select_class = item.game_class
            this.newAccount_games_select_id = item.game_id
            this.newAccount_games_select = item.game_name
            this.newAccount_games_select_class = item.game_class
            //获取 每日成交列表
            this.selectChange()
          }
          this.options.push(options)
          item.game_service?.forEach(gsItem => {
            switch (gsItem.id) {
              case 1:
                this.game_list.push(item)
                break;
              case 2:
                this.game_listEquip.push(item)
                break;
              case 3:
                this.game_listGold.push(item)
                break;
            }
          })
        })
        //获取 1F顶级帐号列表
        apiProductTop({
          pageSize: 6,
          game_id: this.topAccount_games_select_id,
        }).then(res => {
          this.top_account_info = res.data
        })
        //获取 2F最新帐号列表
        apiProductNew({
          pageSize: 6,
          game_id: this.newAccount_games_select_id,
        }).then(res => {
          this.new_account_info = res.data
        })
      })
      apiGameClass({}).then(res => {
        this.gameClass = res.data
      })
      // 轮播图
      apiShowList({}).then(res => {
        this.carouselList = res.data
      })
    },
    /**
     * 退出登录
     */
    logout() {
      if (this.$store.state.loginStatus) {
        apiLogout({}).then(res => {
          this.$message({
            center: true,
            type: 'success',
            message: "退出成功"
          })
        }).catch(err => {
          this.$message({
            center: true,
            type: 'error',
            message: err.msg
          })
        })
      }
      this.$store.commit('del_token')
      this.$store.state.userData = {}
      this.$store.state.loginStatus = false
      sessionStorage.clear();
    },
  },
  mounted() {

  },
  created() {
    //最新交易记录
    setInterval(this.showMarquee, 2000)
    //A-Z
    for (var i = 0; i < 26; i++) {
      this.alphabet.push(String.fromCharCode(65 + i))
    }
    this.alphabet.unshift('全部')
    //遮罩层
    // this.getgame_list()
    //初始化首页数据
    this.onLoad()

    // this.getfirst_recharge_list()
    // this.getGold_list()
    // this.getaccount_recycle_list()
  },
  computed: {}
}

</script>

<style lang='less' scoped>
/deep/ .el-carousel .el-carousel--horizontal * {
  z-index: 1 !important;
}

/deep/ .el-carousel__container {
  height: 450px !important;
}
/deep/ .el-dialog__header{
  padding: 0;
  display: none;
}
/deep/.el-dialog--center .el-dialog__body{
  padding: 0;
}
#home {
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  .background {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    //首页顶部
    .header {
      width: 1200px;
      margin-top: 11px;
      //首页顶部第一层
      .header_first {
        height: 610px;
        display: flex;
        flex-direction: row;
        //热门服务
        .header_first_left {
          width: 243px;
          margin-right: 8px;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          border-radius: 20px;

          .header_first_left_top {
            height: 72px;
            width: 243px;
            background: #FFC121;
            border-radius: 10px 10px 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            .hot_service_img {
              padding: 0 4px 0 5px;
              background: #FFFFFF;
              font-size: 14px;
              border-radius: 4px ;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FFC121;
              margin: 0 7px 0 26px;

            }

            .hot_service_text {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
          }

          .header_first_left_bot {
            padding-top: 20px;
            //height: 358px;
            height: 518px;
            width: 243px;
            background: #FFFFFF;
            border-radius: 0 0 10px 10px;

            .game_type_list {
              display: block;
              padding: 18px 20px 0 31px;
              height: 105px;
              cursor: pointer;
              position: relative;

              .type_name {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                  font-size: 16px;
                  font-family: PingFangSC-Light, PingFang SC;
                  color: #5B5B5B;
                  width: 141px;
                }
              }

              .type_game {
                margin-left: 8px;
                font-size: 14px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #BFBFBF;
                line-height: 20px;
              }

              .game_type_bullet {
                width: 657px;
                height: 570px;
                z-index: 20;
                position: relative;
                padding: 30px 0 10px 58px;
                display: none;
                cursor: auto;

                .game_type_bullet_title {
                  /*height: 100px;*/
                  padding: 0 40px 0 0;

                  .game_type_bullet_title_top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: baseline;
                    //height: 34px;
                    position: relative;

                    img {
                      width: 50px;
                      height: 2px;
                      object-fit: cover;
                      position: absolute;
                      top: 34px;
                      left: 15px;
                    }

                    .bullet_title {
                      font-size: 20px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 500;
                      color: #FFFFFF;
                      line-height: 28px;
                      padding: 0 0 5px 0;
                      background: url("../../assets/image/Home/gameTypeBullet.png") no-repeat;
                      background-position: 50% 100%;
                    }

                    .game_type_bullet_area {
                      font-size: 14px;
                      font-family: PingFangSC-Thin, PingFang SC;
                      font-weight: 300;
                      color: #FFFFFF;
                      line-height: 22px;
                    }
                  }

                  .game_type_bullet_title_bot {
                    font-size: 12px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 17px;

                    .opiton {
                      border-radius: 40px;

                      div {
                        height: 50px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        span {
                          font-size: 12px;
                          font-family: PingFangSC-Light, PingFang SC;
                          font-weight: 400;
                          color: #FFFFFF;
                          line-height: 17px;
                        }

                        span:hover {
                          font-size: 12px;
                          font-family: PingFangSC-Light, PingFang SC;
                          font-weight: 400;
                          color: #FCAD13;
                          line-height: 17px;
                          cursor: pointer;
                        }

                        .active_A-Z {
                          font-size: 12px;
                          font-family: PingFangSC-Light, PingFang SC;
                          font-weight: 400;
                          color: #FCAD13;
                          line-height: 17px;
                        }
                      }
                    }
                  }
                }

                .game_type_bullet_list {
                  height: 430px;
                  margin: 35px 0 0 0;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  align-content: flex-start;
                  overflow: auto;
                  //justify-content: space-between;
                  .game_type_bullet_list_item {
                    width: 84px;
                    height: 130px;
                    display: flex;
                    cursor: pointer;
                    flex-direction: column;
                    align-items: center;

                    .game_type_bullet_list_item_img {
                      width: 80px;
                      height: 80px;
                      overflow: hidden;
                      //background: #D8D8D8;
                      margin-bottom: 10px;
                      border-radius: 20px;

                      img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        transition: 0.3s ease-in-out;
                        border-radius: 20px;
                      }
                    }

                    .game_type_bullet_list_item_text {
                      font-size: 14px;
                      font-family: PingFangSC-Thin, PingFang SC;
                      font-weight: 300;
                      color: #FFFFFF;
                      line-height: 20px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 110px;
                      text-align: center;
                    }
                  }

                  .game_type_bullet_list_item:hover {
                    .game_type_bullet_list_item_img {
                      img {
                        height: 100%;
                        width: 100%;
                        //object-fit: cover;
                        transform: scale(1.1);
                      }
                    }
                  }

                  //每日成交 滚动条

                  #line_not_first_item {
                    margin-left: 25px;
                  }
                }

                .game_type_bullet_list::-webkit-scrollbar-track{
                  border-radius: 10px;
                  background: transparent;
                }

                .game_type_bullet_list::-webkit-scrollbar{
                  width: 4px;
                  height: 4px;
                }

                .game_type_bullet_list::-webkit-scrollbar-thumb {
                  border-radius: 5px;
                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  background: rgba(0, 0, 0, 0.2);
                }
              }

              .game {
                background: url("../../assets/image/Home/background1.png") no-repeat;
                top: -165px;
                left: 202px;
                //display: block;
                .game_type_bullet_title {
                  .game_type_bullet_title_top {
                    .bullet_title {
                      display: flex;
                      flex-direction: row;
                      background: none;

                      div {
                        margin: 0 20px 0 0;
                        padding: 0 0 5px 0;
                        font-size: 16px;
                        font-family: PingFangSC-Thin, PingFang SC;
                        font-weight: 300;
                        color: #FFFFFF;
                        cursor: pointer;
                      }

                      .game_click {
                        background: url("../../assets/image/Home/gameTypeBullet.png") no-repeat;
                        background-position: 50% 100%;
                        font-size: 20px;
                        font-family: PingFangSC-Light, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                      }

                      .game_type_bullet_area {
                        padding: 0 0 5px 0;
                      }

                      #pcGame {
                        padding: 0 0 5px 0;
                        margin: 0 40px 0 0;
                      }

                      #peGame {
                        padding: 0 0 5px 0;
                      }
                    }

                  }
                }
              }

              .first_recharge {
                background: url("../../assets/image/Home/background3.png") no-repeat;
                top: -417px;
                left: 202px;
                //display: block;
                .game_type_bullet_title {
                  .game_type_bullet_title_top {
                    .bullet_title {
                      display: flex;
                      flex-direction: row;
                      background: none;

                      div {
                        margin: 0 20px 0 0;
                        padding: 0 0 5px 0;
                        font-size: 16px;
                        font-family: PingFangSC-Thin, PingFang SC;
                        font-weight: 300;
                        color: #FFFFFF;
                      }

                      .game_click {
                        background: url("../../assets/image/Home/gameTypeBullet.png") no-repeat;
                        background-position: 50% 100%;
                        font-size: 20px;
                        font-family: PingFangSC-Light, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                      }

                      .game_type_bullet_area {
                        padding: 0 0 5px 0;
                      }

                      #pcGame {
                        padding: 0 0 5px 0;
                        margin: 0 40px 0 0;
                      }

                      #peGame {
                        padding: 0 0 5px 0;
                      }
                    }

                  }
                }
              }

              .gold {
                background: url("../../assets/image/Home/background3.png") no-repeat;
                top: -430px;
                left: 202px;
                //display: block;
                .game_type_bullet_title {
                  .game_type_bullet_title_top {
                    .bullet_title {
                      display: flex;
                      flex-direction: row;
                      background: none;

                      div {
                        margin: 0 20px 0 0;
                        padding: 0 0 5px 0;
                        font-size: 16px;
                        font-family: PingFangSC-Thin, PingFang SC;
                        font-weight: 300;
                        color: #FFFFFF;
                      }

                      .game_click {
                        background: url("../../assets/image/Home/gameTypeBullet.png") no-repeat;
                        background-position: 50% 100%;
                        font-size: 20px;
                        font-family: PingFangSC-Light, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                      }

                      .game_type_bullet_area {
                        padding: 0 0 5px 0;
                      }

                      #pcGame {
                        padding: 0 0 5px 0;
                        margin: 0 40px 0 0;
                      }

                      #peGame {
                        padding: 0 0 5px 0;
                      }
                    }

                  }
                }
              }

              //.gold{
              //  background:url("../../assets/image/Home/background3.png") no-repeat;
              //  top: -430px;
              //  left: 202px;
              //}
              //.account_recycle{
              //  background:url("../../assets/image/Home/background4.png") no-repeat;
              //  top: -553px;
              //  left: 202px;
              //  //display: block;
              //}
            }

            .game_type_list:hover {
              background: #F4F4F4;

              .game_type_bullet {
                display: block;
              }
            }

            .first_recharge, .gold , .accountValuation {
              position: relative;

              .inDev {
                width: 268px;
                height: 158px;
                z-index: 3 !important;
                background: url("../../assets/image/Home/inDev.png") no-repeat;
                display: none;
                position: absolute;
                top: -30px;
                left: 220px;
                font-size: 14px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #9A9A9A;
                line-height: 20px;

                div {
                  display: flex;
                  align-items: flex-end;
                  flex-direction: row;
                  justify-content: center;
                  align-content: space-between;
                  height: 131px;
                  padding: 0 0 0 25px;
                }
              }
            }

            .first_recharge:hover, .gold:hover ,.accountValuation:hover {
              .inDev {
                display: block;
              }
            }

            .first_recharge {
              //background:url("../../assets/image/Home/background2.png") no-repeat;
              //top: -306px;
              //left: 202px;
              .type_name {
                img {
                  padding: 10px;
                }
              }
            }
          }
        }

        //轮播图
        .header_first_mid {
          width: 697px;
          height: 450px;
          margin-right: 8px;

          /deep/ .small {
            margin: 0;
            width: 697px;
            height: 450px;

            img {
              width: 100%;
              height: 100%;
              //object-fit: cover;
            }
          }

          /deep/ .el-carousel__arrow:hover {
            color: #FFFFFF;
          }

          /deep/ .el-carousel__arrow {
            height: 53px;
            width: 53px;
            font-size: 24px;
          }

          .trade {
            margin: 9px 0 0 0;
            height: 151px;
            background: linear-gradient(270deg, #EAE7FF 0%, #FFFFFF 34%, #FFFFFF 62%, #EDEDFF 100%);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            border-radius: 20px;

            .trade_top {
              height: 75px;
              display: flex;
              align-items: center;
              justify-content: center;

              .trade_top_img {
                width: 231.86px;
                height: 25px;
                margin: 0 12px 0 0;
                img{
                  width: 100%;
                }
              }

              .trade_top_text {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 22px;

                span {
                  font-size: 23px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 600;
                  color: #FCAD13;
                  line-height: 32px;
                  padding: 0 10px;
                }
              }
            }

            .trade_bot {
              height: 38px;
              background: #151E50;
              border-radius: 20px;
              padding: 19px 20px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: center;
              color: white;

              div {
                font-size: 16px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 22px;
                margin: 0 32px;
                display: flex;
                align-items: center;

                span {
                  margin: 0 0 0 10px;
                  font-size: 20px;
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }
            }
          }
        }

        //个人信息
        .header_first_right {
          width: 243px;
          height: 450px;
          display: inline-block;
          float: left;

          .header_first_right_top {
            width: 243px;
            height: 450px;
            background: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;

            .userInfo {
              height: 105px;
              display: flex;
              align-items: center;
              flex-direction: row;

              .userInfo_img {
                height: 70px;
                width: 70px;
                margin: 0 17px 0 22px;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  border-radius: 35px;
                }
              }

              .userInfo_account {
                font-size: 14px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #8F8F8F;

                .userInfo_account_top {
                  font-size: 16px;
                  font-family: PingFangSC-Thin, PingFang SC;
                  font-weight: 300;
                  color: #000000;
                  margin-bottom: 2px;
                }

                .userInfo_account_bot {
                  span:hover {
                    cursor: pointer;
                    color: #FCAD13;
                  }
                }

              }
            }

            .btn_ground {
              display: flex;
              flex-direction: column;
              align-items: center;

              a, button {
                width: 200px;
                height: 50px;
                /*margin: 5px 20px;*/
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                cursor: pointer;
                transition: all ease .3s;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;

                img {
                  float: left;
                }

                div {
                  display: inline-block;
                  float: left;
                  margin: 0 12px 0 0;
                }
              }

              .buyNumber {
                background: #FFC121;
                border-radius: 8px;
                border-style: none;
                margin-bottom: 9px;
                .help_wrapper{
                  position: absolute;
                  top: 12px;
                  left: 162px;
                  // display: none;
                  width: 87px;
                  height: 21px;
                  background: rgba(255,255,255,0.80);
                  border-radius: 4px;
                  box-shadow: 3px 4px 7px -1px rgba(0,0,0,0.25);
                  backdrop-filter: blur(9px);
                  margin: 0;
                  padding: 2px 9px;
                  box-sizing: border-box;
                  button{
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    color: #6a7db8;
                    width: auto;
                    height: 15px;
                    padding: 0;
                    margin: 0 0 0 16px;
                    line-height: 15px;
                    border-bottom: 1px #8B9BCB solid;
                    position: relative;
                    border-radius: unset;
                    img,i{
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      left: -20px;
                      top: -1px;
                      font-size: 18px;
                    }
                  }
                }
                &:hover{
                  .help_wrapper{
                    margin: 0;
                    padding: 2px 9px;
                    button{
                      &:hover{
                        color: #112768;
                      }
                    }
                  }
                }
              }

              .sellNumber {
                background: #FFC121;
                border-radius: 8px;
                border-style: none;
                margin-bottom: 9px;
              }

              .Recycle {
                border-radius: 8px;
                background-color: #ffffff;
                border: 1px solid #434ED6;
                background: url('../../assets/image/Home/recycleIco.png') no-repeat left;
                background-position: 45px;
                position: relative;
                color: #434ED6;
                div {
                  margin-left: 33px;
                }
                .help_wrapper{
                  position: absolute;
                  top: 12px;
                  left: 162px;
                  display: none;
                  width: 213px;
                  height: 49px;
                  background: rgba(255,255,255,0.80);
                  border-radius: 4px;
                  box-shadow: 3px 4px 7px -1px rgba(0,0,0,0.25);
                  backdrop-filter: blur(9px);
                  margin: 0;
                  padding: 6px 15px;
                  box-sizing: border-box;

                  button{
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    color: #6a7db8;
                    width: auto;
                    height: 15px;
                    padding: 0;
                    margin: 0 0 0 16px;
                    line-height: 15px;
                    border-bottom: 1px #8B9BCB solid;
                    position: relative;
                    border-radius: unset;
                    img,i{
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      left: -20px;
                      top: -1px;
                      font-size: 18px;
                    }
                  }
                }
                &:hover{
                  .help_wrapper{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin: 0;
                    padding: 6px 15px;
                    button{
                      &:hover{
                        color: #112768;
                      }
                    }
                  }
                }
              }

              a:hover {
                -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
              }
              .buyNumber:hover,.sellNumber:hover{
                background: #FFCF53;
              }

              .Recycle:hover {
                background: #434ED6 url('../../assets/image/Home/recycleIco_white.png') no-repeat left;
                background-position: 45px;
                div {
                  margin-left: 33px;
                  color: #FFFFFF;
                }
              }
            }

            .verify {
              margin: 23px 0 0 0;
              float: left;
              border-radius: 20px;
              display: flex;
              flex-direction: column;
              padding: 0 22px 0 22px;

              .header_second_right_item {
                width: 200px;
                //margin-top: 23px;
                font-size: 16px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #5B5B5B;
                line-height: 22px;
                .header_second_right_text{
                  margin: 0 0 5px;
                }
                .header_second_input_box{
                  display: flex;
                  align-items: center;
                  border: 1px solid #CECDFC;
                  border-radius: 22px;


                  .header_second_right_inptut {
                    width: 104px;
                    height: 35px;
                    //width: 104px;
                    //height: 44px;
                    border-radius: 22px;
                    border: none;
                    padding: 0 10px 0 20px;
                  }

                  span {
                    width: 66px;
                    height: 35px;
                    //background: url("../../assets/image/Home/searchBtn.png") no-repeat;
                    //background-size: cover;/
                    //background: lightpink;
                    background: linear-gradient(180deg, #88A9FF 0%, #5C5ADE 100%);
                    cursor: pointer;
                    line-height: 35px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 18px;
                  }
                }


              }

              .header_second_right_item:last-of-type {
                margin: 10px 0 0 0;
              }
            }
          }

          .header_first_right_bottom {
            margin: 8px 0 0 0;
            height: 122px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            padding: 10px 20px 18px;

            .announcement_title {
              height: 35px;
              border-bottom: 1px solid #BFBFBF;
              display: flex;
              flex-direction: row;
              align-items: center;

              .a_title_img {
                margin: 0 3px 0 0;
              }

              .a_title_text {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #434343;
                line-height: 28px;
                margin: 0 76px 0 0;
              }

              .a_title_more {
                font-size: 16px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #8F8F8F;
                line-height: 22px;
                cursor: pointer;
              }

              .a_title_more:hover {
                color: #FCAD13;
              }
            }

            .announcement_content {
              display: flex;
              flex-direction: column;

              a {
                margin: 8px 0 0 0;
                width: 203px;
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #BFBFBF;
                line-height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
              }
            }
          }
        }
      }

      //首页顶部第二层
      .header_third {
        height: 50px;
        margin-top: 7px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #CDD1FF;
        width: 1200px;
        //小喇叭
        .header_third_img {
          float: left;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 0 23px;

          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            margin: 0 23px 0 0;
            float: left;
          }

          div {
            display: inline-block;
            float: left;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
          }
        }

        //温馨提示内容
        .header_third_text {
          display: inline-block;
          float: left;
          width: 1000px;
          line-height: 50px;
          height: 50px;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          overflow: hidden;

          .notice-card-wrapper {
            display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
            -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
            overflow: hidden; /*超出的文本隐藏*/
            text-overflow: ellipsis; /* 溢出用省略号*/
            -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
          }

          .notice-card-wrapper:hover {
            animation: myMove 30s linear infinite; // 重点，定义动画
            animation-fill-mode: forwards;
            width: 200%;
            @keyframes myMove {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(-2500px);
              }
            }
          }
        }
      }

      //首页顶部第三层
      .header_four {
        margin: 8px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a {
          width: 394px;
          height: 114px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        a:hover {
          animation: header_fourMove 0.2s linear infinite; // 重点，定义动画
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
          @keyframes header_fourMove {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }


  //首页主体内容
  .main_body {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    // 1F & 2F 左
    .account_ground {
      width: 898px;
      margin-right: 8px;

      .top_account {
        background: #FFFFFF url("../../assets/image/Home/accountBG.png") no-repeat;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        padding: 30px 37px 32px 42px;
        //1F 顶部列表
        .top_account_title {
          display: flex;
          flex-direction: row;
          margin-bottom: 32px;
          justify-content: space-between;

          .top_account_title_logo {
            font-size: 30px;
            font-family:  PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;

          }

          .top_account_title_list {
            width: 550px;
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            padding: 13px 0 6px;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 22px;
            justify-content: space-between;
            white-space: nowrap;
            overflow-y: hidden;
            overflow-x: scroll;
            display: flex;

            .top_account_title_list_item {
              margin-right: 20px;
              cursor: pointer;
            }

            #active {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 500;
              position: relative; //给当前元素添加相对定位,也就是after的父级
              &:after {
                content: " ";
                width: 68.18182%;
                height: 2px;
                background: #FCAD13;
                position: absolute;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
              }
            }

          }

          .top_account_title_list::-webkit-scrollbar-track {
            //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            //border-radius: 10px;
            //background: rgba(0,0,0,0.1);
          }

          .top_account_title_list::-webkit-scrollbar  {
            width: 3px;
            height: 3px;
          }

          .top_account_title_list::-webkit-scrollbar-thumb  {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
            background: rgba(0, 0, 0, 0.1);
            display: none;
          }

          .top_account_title_list_more {
            display: flex;
            align-items: center;
            margin-left: 27px;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 22px;
            cursor: pointer;
          }

          .top_account_title_list_more:hover {
            color: #FCAD13;
          }
        }

        .top_account_title:hover {
          .top_account_title_list::-webkit-scrollbar-thumb{
            display: block;
          }
        }

        //1F 主体内容
        .top_account_content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          /*justify-content: space-between;*/

          .top_account_content_item {
            width: 266px;
            background: #FFFFFF;
            margin: 0 7px 9px 0;
            cursor: pointer;

            .top_account_content_item_img {
              height: 174px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
              border-radius: 10px 10px 0 0;
              background: #FFFFFF;
              overflow: hidden;
              img {
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 10px 10px 0 0;
              }
            }

            .top_account_content_item_info {
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
              padding: 14px 17px 17px 19px;
              border-radius: 0 0 10px 10px;
              background: #FFFFFF;

              .top_account_content_item_info_top {
                width: 226px;
                height: 67px;
                font-size: 16px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #5B5B5B;
                line-height: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                margin-bottom: 10px;
                text-align: justify; /*实现两端对齐*/
                text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
                word-break: break-all; /*允许在单词内换行*/

                span {
                  border-radius: 10px;
                  border: 1px solid #434ED6;
                  font-size: 14px;
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 400;
                  color: #434ED6;
                  line-height: 20px;
                  padding: 0 9px;
                }
              }

              .top_account_content_item_info_bot {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;
                height: 42px;

                #top_account_info_left {
                  display: flex;
                  flex-direction: column;

                  #top_account_info_hot {
                    font-size: 14px;
                    font-family: PingFangSC-Thin, PingFang SC;
                    font-weight: 300;
                    color: #5B5B5B;
                    display: flex;
                    align-items: center;

                    img {
                      width: 21.24px;
                      height: 13.38px;
                      object-fit: cover;
                      margin: 0 5px 0 0;
                    }

                  }

                  #top_account_info_date {
                    font-size: 14px;
                    font-family: PingFangSC-Thin, PingFang SC;
                    font-weight: 300;
                    color: #8F8F8F;
                    line-height: 20px;
                  }
                }

                #top_account_info_right {
                  font-size: 20px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 500;
                  color: #FCAD13;
                  height: 42px;

                  span {
                    font-size: 30px;
                    line-height: 42px;
                  }
                }
              }
            }

            .top_account_content_item_detail {
              display: none;
              width: 458px;
              height: 294px;
              background: rgba(0,0,0,0.8000);
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
              border-radius: 0 10px 10px 0;
              position: absolute;
              top: 0px;
              left: 256px;
              padding: 10px 20px 20px 30px;

              #top_account_content_item_detail_title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #FCAD13;
                line-height: 22px;
                margin-bottom: 7px;
              }

              #top_account_content_item_detail_content {
                font-size: 14px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #FFFFFF;
                line-height: 20px;
                width: 448px;
                height: 260px;
                overflow: hidden;
              }
            }
          }

          .top_account_content_item:hover {
            -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            animation: grid-contentMove 0.2s linear infinite; // 重点，定义动画
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            position: relative;
            z-index: 999999;
            @keyframes grid-contentMove {
              0% {
                transform: translateY(0);
              }
              100% {
                transform: translateY(-5px);
              }
            }

            .top_account_content_item_info {
              .top_account_content_item_info_bot {
                #top_account_info_right {
                  color: #FCA700;
                }
              }
            }

            .top_account_content_item_detail {
              display: block;
              z-index: -1 !important;
            }

            .top_account_content_item_detail:hover {
              display: none;
              z-index: -1 !important;
            }
          }
        }
      }

      .latest_account {
        background: #FFFFFF url("../../assets/image/Home/accountBG.png") no-repeat;
        display: flex;
        flex-direction: column;
        padding: 30px 37px 32px 42px;
        //2F 顶部列表
        .latest_account_title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 32px;

          .latest_account_title_logo {
            font-size: 30px;
            font-family:  PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;
          }

          .latest_account_title_list {
            width: 550px;
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            padding: 13px 0 6px;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 22px;
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;

            .latest_account_title_list_item {
              margin-right: 20px;
              cursor: pointer;
            }

            #active {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 500;
              position: relative; //给当前元素添加相对定位,也就是after的父级
              &:after {
                content: " ";
                width: 68.18182%;
                height: 2px;
                background: #FCAD13;
                position: absolute;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }

          .latest_account_title_list::-webkit-scrollbar-track{
            //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            //border-radius: 10px;
            //background: rgba(0,0,0,0.1);
          }

          .latest_account_title_list::-webkit-scrollbar  {
            width: 3px;
            height: 3px;
          }

          .latest_account_title_list::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
            background: rgba(0, 0, 0, 0.1);
            display: none;
          }

          .latest_account_title_list_more {
            margin-left: 12px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 22px;
            cursor: pointer;
          }

          .latest_account_title_list_more:hover {
            color: #FCAD13;
          }
        }

        .latest_account_title:hover {
          .latest_account_title_list::-webkit-scrollbar-thumb {
            display: block;
          }
        }

        //2F 主体内容
        .latest_account_content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          /*justify-content: space-between;*/

          .latest_account_content_item {
            width: 266px;
            background: #FFFFFF;
            margin: 0 7px 9px 0;
            cursor: pointer;

            .latest_account_content_item_img {
              height: 174px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
              border-radius: 10px 10px 0 0;
              background: #FFFFFF;
              overflow: hidden;
              img {
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 10px 10px 0 0;
              }
            }

            .latest_account_content_item_info {
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
              padding: 14px 17px 17px 19px;
              border-radius: 0 0 10px 10px;
              background: #FFFFFF;

              .latest_account_content_item_info_top {
                width: 226px;
                height: 67px;
                font-size: 16px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #5B5B5B;
                line-height: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                margin-bottom: 10px;
                text-align: justify; /*实现两端对齐*/
                text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
                word-break: break-all; /*允许在单词内换行*/

                span {
                  border-radius: 10px;
                  border: 1px solid #434ED6 ;
                  font-size: 14px;
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 400;
                  color: #434ED6 ;
                  line-height: 20px;
                  padding: 0 9px;
                }
              }

              .latest_account_content_item_info_bot {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;
                height: 42px;

                #latest_account_info_left {
                  display: flex;
                  flex-direction: column;

                  #latest_account_info_hot {
                    font-size: 14px;
                    font-family: PingFangSC-Thin, PingFang SC;
                    font-weight: 300;
                    color: #5B5B5B;
                    display: flex;
                    align-items: center;

                    img {
                      width: 21.24px;
                      height: 13.38px;
                      object-fit: cover;
                      margin: 0 5px 0 0;
                    }

                  }

                  #latest_account_info_date {
                    font-size: 14px;
                    font-family: PingFangSC-Thin, PingFang SC;
                    font-weight: 300;
                    color: #8F8F8F;
                    line-height: 20px;
                  }
                }

                #latest_account_info_right {
                  font-size: 20px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 500;
                  color: #FCAD13;

                  span {
                    font-size: 30px;
                    line-height: 42px;
                  }
                }
              }
            }

            .latest_account_content_item_detail {
              display: none;
              width: 458px;
              height: 294px;
              background: rgba(0,0,0,0.8000);
              box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
              border-radius: 0 10px 10px 0;
              position: absolute;
              top: 0px;
              left: 256px;
              padding: 10px 20px 20px 30px;

              #latest_account_content_item_detail_title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #FCAD13;
                line-height: 22px;
                margin-bottom: 7px;
              }

              #latest_account_content_item_detail_content {
                font-size: 14px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #FFFFFF;
                line-height: 20px;
                width: 448px;
                height: 260px;
                overflow: hidden;
              }
            }
          }

          .latest_account_content_item:hover {
            -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            animation: grid-contentMove 0.2s linear infinite; // 重点，定义动画
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            position: relative;
            z-index: 999999;
            @keyframes grid-contentMove {
              0% {
                transform: translateY(0);
              }
              100% {
                transform: translateY(-5px);
              }
            }

            .latest_account_content_item_info {
              .latest_account_content_item_info_bot {
                #latest_account_info_right {
                  color: #FCA700;
                }
              }
            }

            .latest_account_content_item_detail {
              display: block;
              z-index: -1 !important;
            }

            .latest_account_content_item_detail:hover {
              display: none;
              z-index: -1 !important;
            }
          }
        }
      }
    }

    // 每日成交 右
    .daily_transaction {
      width: 293px;
      height: 1598px;
      background: #FFFFFF url("../../assets/image/Home/dailyBG.png") no-repeat;
      //每日成交顶部logo
      .daily_transaction_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 32px 20px 6px 26px;
        border-bottom: 1px solid #E2E2E2;;

        .daily_transaction_title_more {
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #000000;
          line-height: 22px;

          //margin-left: 84px;
        }

        .daily_transaction_title_more:hover {
          color: #FCAD13;
        }
      }

      //每日成交下拉框
      .daily_transaction_filter {
        margin: 26px 20px 0 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .daily_transaction_filter_title {
          width: 116px;
          height: 38px;
          line-height: 38px;
          margin-right: 9px;
          background: linear-gradient(270deg, rgba(238, 238, 238, 0) 0%, #283748 100%);
          border-radius: 100px 0px 0px 100px;

          span:first-of-type {
            width: 8px;
            height: 8px;
            background: #FFFFFF;
            border-radius: 4px;
            margin-left: 12px;
            display: inline-block;
          }

          span:last-of-type {
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            font-size: 16px;
            margin-left: 7px;
          }
        }

        .daily_transaction_filter_select {
          /deep/ .el-select {
            width: 132px;
            height: 38px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #C0C4CC;
            line-height: 14px;
          }

          /deep/ .el-input.is-focus .el-input__inner {
            border-color: #FCAD13 !important;
          }

          /deep/ .el-select .el-input__inner:focus {
            border-color: #FCAD13 !important;
          }

          /deep/ .el-select-dropdown__item.hover {
            background-color: #FFFBF3;
          }

          /deep/ .el-select-dropdown__item.selected {
            color: #FCAD13;
          }

          /deep/ .el-input__inner {
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #C0C4CC;
          }
        }
      }

      //每日成交内容
      .daily_transaction_content {
        height: 1485px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 28px 0 0 19px;
        //padding-top: 28px;
        //border-left: 1px solid #596471;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: nowrap;

        .daily_transaction_content_item {
          //height: 130px;
          height: 80px;
          width: 232px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
          //box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          margin: 0 0 21px 0px;
          cursor: pointer;
          padding: 10px;
          display: flex;
          flex-direction: row;
          position: relative;
          flex-shrink: 0;

          .DTCI_top {
            position: absolute;
            top: -3px;
            left: -3px;
          }

          .DTCI_img {
            margin: 0 14px 0 0;

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .DTCI_content {
            .DTCI_gameName {
              font-size: 12px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              line-height: 17px;
              margin: 0 0 5px 0;
            }

            .DTCI_orderNum {
              width: 111px;
              height: 31px;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 31px;
              padding: 0 0 5px 10px;
              margin: 0 0 5px 0;
              border-bottom: 1px solid #E2E2E2;
              background: url("../../assets/image/Home/dealCount.png") no-repeat;
              //background-size:  22px;
            }

            .DTCI_date {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              line-height: 17px;
            }
          }
        }

        .daily_transaction_content_item:last-of-type {
          margin: 0 0 5px 0px;
        }

      }

      //每日成交 滚动条
      .daily_transaction_content::-webkit-scrollbar-track{
        //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        //border-radius: 10px;
        //background: rgba(0,0,0,0.1);
      }

      .daily_transaction_content::-webkit-scrollbar  {
        width: 4px;
        height: 4px;
      }

      .daily_transaction_content::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .appDownload{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 80px;
    background: rgba(40, 55, 72, 0.6);
    z-index: 999999;
    .btn{
      margin: 0 30px;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    .ad-conetnt{
      width: 1200px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .conetnt{
        height: 60px;
        object-fit: contain;
      }
      .qrcode{
        position: absolute;
        bottom: 12px;
        right: 0;
      }
    }
  }

}

</style>
<style lang="less">
  .bannerWxDialog{
    position: relative;
    border-radius: 20px;
    .banner-dia-main{
      width: 303px;
      height: 423px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .banner-dia-top{
        width: 303px;
        height: 123px;
        background: #fcad13;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #fff;
        padding-top: 25px;
        // box-sizing: border-box;
      }
      .banner-dia-wx{
        position: absolute;
        top: 68px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        >img{
          width: 250px;
          height: 250px;
        }
        .banner-dia-c{
          margin: 13px 0 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          img{
            width: 13px;
            height: 16px;
            vertical-align: middle;
          }
        }
        .banner-dia-sys{
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #32b440;
          img{
            width: 22px;
            height: 22px;
            vertical-align: middle;
          }
        }
      }
    }
    .banner-dia-close{
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -40px;
      left: 136.5px;
    }
  }
</style>
