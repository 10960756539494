<template>
  <div class="sell_pop" v-show="sellPop" @click="esc">
    <div class="card" @click.stop>
      <div class="title">
        <img src="@/assets/image/Home/sellPop/title.png" alt="">
      </div>
      <div class="content">
        <a class="btn" @click="sellClick('AccountSell')">
          <div class="btnHead">
            <span></span>
            <img src="@/assets/image/Home/sellPop/btn1.png" alt="">
          </div>
          <div class="btnBody">

            1.美工截取账号详情图，编辑标题挂售到官方网站（约30分钟）<br/><br/>
            2.平台在交流群等渠道同步宣传，提高曝光度以匹配更多买家<br/><br/>
            3.买家确认交易并完成付款，由客服拉群聊安排完成交易流程<br/><br/>
            4.买卖双方游戏资料交接完成后，财务实时放款，交易完成<br/><br/>
            5.注：商品上架期间卖家可以正常使用账号，如需更新截图请联系客服进行修改

          </div>
          <el-button type="text" @click.stop="goHelp(259,10)">如何卖号</el-button>
        </a>
        <a class="btn" @click="sellClick('AccountRecycle')" >
          <div class="btnHead">
            <span></span>
            <img src="@/assets/image/Home/sellPop/btn2.png" alt="">
          </div>
          <div class="btnBody">
            1.号商对账号进行报价，号主决定是否回收。<br/><br/>
            2.快速变现，当天收款。<br/><br/>
            3.所有交易一律由官方客服拉组担保中介交易<br/><br/>
            4.注：合作号商并非官方人员。
          </div>
          <el-button type="text" @click.stop="goHelp(252,10)">如何回收</el-button>
        </a>
        <a class="btn" @click="sellClick('Vouch')">
          <div class="btnHead">
            <span></span>
            <img src="@/assets/image/Home/sellPop/btn3.png" alt="">
          </div>
          <div class="btnBody">
            1.双方私下联系谈好价格，确认账号详情后联系客服<br/><br/>
            2.客服直接拉组中介交易<br/><br/>
            3.担保中介不限游戏类目，支持市面所有游戏账号交易<br/><br/>
          </div>
          <el-button type="text" @click.stop="goHelp(257,10)">如何找担保中介</el-button>
        </a>
      </div>
      <img class="escBtn" src="@/assets/image/AccountDeal/close.png" alt=""  @click="esc">
    </div>
  </div>
</template>
<script>

export default {
  name: 'cusSerCenter',
  props: {
    sellPop: Boolean
  },
  components: {},
  data() {
    return {}
  },

  methods: {
    /**
     * @description: 我要卖-底部跳转帮助中心
     * @param {*} id
     * @param {*} cat_id 分类id
     * @return {*}
     */
    goHelp(id,cat_id) {
      let newPage = this.$router.resolve({
        name: 'helpDetail',
        query: {
          id,
          cat_id
        }
      })
      window.open(newPage.href, '_blank')
    },
    /**
     * 关闭
     */
    esc() {
      let bool = false
      this.$parent.escSellPop(bool)
    },
    /**
     * 发布账号
     * @param name router.name
     */
    sellClick(name){
      this.esc()
      this.$router.push({
        name:name,
      });
    }

  },
  mounted() {
  },
  watch: {},
  computed: {}
}
</script>

<style lang='less' scoped>
.sell_pop {
  width: 100%;
  height: 100%;
  //opacity: 0.73;
  background: rgba(0, 0, 0, 0.73);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    //width: 718px;
    min-height: 493px;
    border-radius: 5px;
    background: linear-gradient(180deg, #bfcdf2 0%, #ffffff 100%);
    position: relative;
    .title {
      height: 77px;
      border-radius: 5px 5px 0 0;
      background: linear-gradient(97deg, #6e7ca0 0%, #051335 100%);
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.31);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content {
      padding: 30px 23px 41px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .btn {
        margin: 0 3px;
        width: 250px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.20);
        cursor: pointer;
        position: relative;
        button{
          position: absolute;
          bottom: 15px;
          padding:0;
          left: 50%;
          transform: translateX(-50%+8px);
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #8B9BCB;
          height: 15px;
          line-height: 12px;
          border-bottom: 1px #8B9BCB solid;
          border-radius: unset;
          &::before{
            content: '';
            position: absolute;
            left: -17px;
            top: -1px;
            width: 15px;
            height: 15px;
            display: inline-block;
            background: url('./../../../assets/image/Home/sellPop/help.png') no-repeat;
            background-size: contain;
          }
          &:hover{
            color: #FCAD13;
            border-bottom: 1px solid #FCAD13;
            &::before{
              background: url('./../../../assets/image/Home/sellPop/helpHover.png') no-repeat;
              background-size: contain;
            }
          }
        }
        .btnHead {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 0 20px;

          span {
            width: 40px;
            height: 8px;
            border-radius: 0 0 8px 8px;
            background: #fca700;
            margin: 0 0 25px;
          }

        }

        .btnBody {
          padding: 0 19px 47px 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Light;
          font-weight: 300;
          text-align: LEFT;
          color: #82a2c8;
          line-height: 17px;
          text-align: justify; /*实现两端对齐*/
          text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
          word-break: break-all; /*允许在单词内换行*/
        }
      }

      .btn:hover {
        box-shadow: 0px 2px 23px 0px rgba(252,167,0,0.40);
        animation: header_fourMove 0.2s linear infinite; // 重点，定义动画
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        @keyframes header_fourMove {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-10px);
          }
        }
      }

    }

    .escBtn{
      position: absolute;
      bottom: -50px;
      left: 390px;
      cursor: pointer;
    }
  }

}
</style>
